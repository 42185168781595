import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { message } from 'antd';

import TextField from '../../../common/TextField';
import PasswordField from '../../../common/PasswordField';
import PhoneInput from '../../../common/PhoneInput';
import Button from '../../../common/Button';
import { UserSignup } from '../../../types/user';
import FormValidation from '../../../framework/FormValidation';
import { SignupSchema } from '../../../schema/user';
import UserController from '../../../controllers/user';
import BaseAuthForm from '../../../common/BaseAuthForm';

import styles from './signup.module.scss';
import Head from '../../../common/Head';

interface SignupProps {
  history: any;
}

const Signup = (props: SignupProps) => {
  const emptyState = {
    email: '',
    password: '',
    phone: '',
    username: '',
    first_name: '',
    last_name: '',
    company_name: '',
  };
  const [data, setData] = useState<UserSignup>(emptyState);
  const [errorText, setErrorText] = useState<{ [key: string]: string }>(
    emptyState
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange =
    (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setData({
        ...data,
        [field]: e.target.value,
      });
      setErrorText({
        ...errorText,
        [field]: '',
      });
    };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = FormValidation.validateForm(SignupSchema, data);
    if (FormValidation.hasErrors(errors)) {
      setErrorText({
        ...errors,
      });
      return;
    }
    setLoading(true);
    try {
      const controller = new UserController();
      const result = await controller.signupUser(data);
      setLoading(false);
      message.success(result, 5);
      props.history.push({
        pathname: '/otp/verify',
        state: {
          email: data.email,
          username: data.username,
          phone: data.phone,
        },
      });
    } catch (err) {
      setLoading(false);
      if (err instanceof Error) {
        message.error(err.message, 5);
      }
    }
  };

  return (
    <div>
      <Head
        title="Signup"
        description="Unlock the power of Kafka with Bitnimbus.io. Access your console to deploy fully managed services effortlessly in just a few clicks."
      />
      <BaseAuthForm
        title="Sign up"
        styleOverrides={{
          padding: '1.3rem 2.5rem',
        }}
      >
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.nameInput}>
            <TextField
              label="First Name"
              id="first_name"
              value={data.first_name}
              errorText={errorText.first_name}
              onChange={handleChange('first_name')}
            />
            <TextField
              label="Last Name"
              id="last_name"
              value={data.last_name}
              errorText={errorText.last_name}
              onChange={handleChange('last_name')}
            />
          </div>
          <TextField
            id="company_name"
            value={data.company_name}
            onChange={handleChange('company_name')}
            errorText={errorText.company_name}
            label="Comany Name (Optional)"
          />
          <TextField
            id="email"
            value={data.email}
            onChange={handleChange('email')}
            errorText={errorText.email}
            label="Email"
          />
          <PhoneInput
            value={data.phone}
            onChange={(value) => {
              setData({ ...data, phone: value });
              setErrorText({ ...errorText, phone: '' });
            }}
            errorText={errorText.phone}
          />
          <TextField
            id="username"
            value={data.username}
            onChange={handleChange('username')}
            errorText={errorText.username}
            label="Username"
            infoText={
              <ul style={{ margin: 0 }}>
                <li>Minimum of 5 characters and maximum of 50 characters</li>
                <li>Alphabets, numbers and underscore are allowed</li>
                <li>First character should be an alphabet</li>
              </ul>
            }
          />
          <div className={styles.inputField}>
            <PasswordField
              password={data.password}
              onChange={handleChange('password')}
              errorText={errorText.password}
              showInfo={true}
            />
          </div>
          <div className={styles.terms}>
            <p>
              By creating an account, you agree to our &nbsp;
              <a
                href="https://bitnimbus-public-assets.us-east-1.linodeobjects.com/Terms%20of%20Services.pdf"
                title="Terms of Service"
                target="__blank"
              >
                Terms of Service
              </a>
              &nbsp; and &nbsp;
              <a
                href="https://bitnimbus-public-assets.us-east-1.linodeobjects.com/Privacy%20Policy.pdf"
                title="Privacy Policy"
                target="__blank"
              >
                Privacy Policy
              </a>
            </p>
          </div>
          <Button type="submit" label="Sign up" loading={loading} />
          <hr className={styles.divider} />
          <div className={styles.formFooter}>
            <p>Already have an account?</p>
            <Link to="/">
              <Button label="Log In" onClick={() => {}} variant="outlined" />
            </Link>
          </div>
        </form>
      </BaseAuthForm>
    </div>
  );
};

export default Signup;
